<template>
  <v-main>
    <v-container fluid class="h-100">
      <v-row justify="center" align="center" class="h-100" no-gutters>
        <v-col md="6" sm="10">
          <h1>Cloudshelf</h1>
          <router-view></router-view> </v-col></v-row></v-container
  ></v-main>
</template>
<script>
export default {};
</script>